<template lang="pug">
  div.d-flex.flex-column
    HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" :indexSearch="indexSearch")
    b-row.content-scrollable-sticky.m-0.justify-content-center(style="margin-top: -12px !important;")
      b-col#nav-sticky-horizontal.content-scrollable-sticky-inner(cols='12' style='padding: 0 !important; height: 100% !important')
        ul.d-flex
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'all' ? 'current' : ''" @click="tabActive = 'all'")
              span Tout ({{ getCountDataByStatus("all") }})
          li(v-for="(status, index) of statusList" :key="index" @click="changeTab(status.value)")
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == status.value ? 'current' : ''" @click="tabActive = status.value")
              span {{ status.label }} ({{ getCountDataByStatus(status.value) }})
    .d-flex.w-100.h-100(style="overflow: hidden;")
      .text-center.flex-center.w-100(v-if='isLoadingSmsList || !headerLoaded')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement de la liste des sms...
      sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :tagguerButton="tagguerButton")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";
import { smsStatus } from "@/types/api-orisis/enums/enums";

export default {
  components: {
    SyncGrid,
    HeaderGrid,
  },
  data() {
    return {
      indexSearch: 0,
      name: "sms",
      uid: "sms",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],
      statusList: smsStatus,
      dateRanger: false,
      tvaModeButton: false,
      addButton: false,
      searchButton: true,
      tagguerButton: false,
      archiveMode: false,
      searchPlaceholder: "Rechercher un sms",
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: false,
      },
      {
        type: "date",
        field: "sendAt",
        headerText: "Envoyé le",
        width: 160,
        minWidth: 160,
        format: "formatDateTimeOptions",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        direction: "descending",
      },
      {
        type: "string",
        field: "collaborator.phoneNumber",
        headerText: "Destinataire",
        width: 180,
        minWidth: 180,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "collaborator.lastName",
        headerText: "Nom",
        width: 180,
        minWidth: 180,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "collaborator.firstName",
        headerText: "Prénom",
        width: 180,
        minWidth: 180,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "message",
        headerText: "Message",
        width: "auto",
        minWidth: 300,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        clipMode: "EllipsisWithTooltip",
        template: "stripHtmlTemplate",
        filter: { type: "Menu", operator: "contains" },
      },
      {
        type: "string",
        field: "status",
        headerText: "Statut",
        width: 110,
        maxWidth: 110,
        template: "statusTemplate",
        nature: "smsStatus",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        filterTemplate: "statusFilterTemplate",
      },
    ];
  },
  computed: {
    ...mapGetters(["smsList", "isLoadingSmsList"]),
    dataFiltered() {
      if (this.statusList) {
        if (this.tabActive == "all") {
          return this.$store.getters["smsList"];
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == this.tabActive
          );
          return this.$store.getters["smsList"].filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === this.tabActive
          );
        }
      } else {
        return [];
      }
    },
    dataOrigine() {
      return this.$store.getters["smsList"];
    },
  },
  created() {
    this.getHistorySms({}).then(async (res) => {
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
  },
  methods: {
    ...mapActions(["getHistorySms"]),
    formatCurrency,
    setColumnsByObject,
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    async changeTab(tab) {
      this.tabActive = tab;
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.archive = true;
      } else if (args.item.type == "current") {
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    getCountDataByStatus(status) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        return this.dataOrigine.filter((elem) =>
          statusInfos.query
            ? statusInfos.query.includes(elem.status)
            : elem.status === status
        ).length;
      }
    },
  },
};
</script>
